


























































































































































































import { Component, Watch } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import VueBase from '@/VueBase'
import { VulnListObj } from './types'
import VulnCard from './components/vulnCard.vue'
import ScaCard from './components/ScaCard.vue'
import ScanCard from './components/ScanCard.vue'

Component.registerHooks([
  'beforeRouteEnter',
]);
@Component({name: 'VulnList',components: { VulnCard, ScaCard, ScanCard }})
export default class VulnList extends VueBase {
  private inteRules = {
    obj: [{ required: true, message: '请选择应用', trigger: 'blur' }],
    title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
    type: [{ required: true, message: '请选择缺陷类型', trigger: 'blur' }],
    level: [{ required: true, message: '请选择缺陷等级', trigger: 'blur' }],
  }
  private projectOtions: any = []
  private typeOptions: any = []
  private priorityOtions: any = []
  private settingInte: any = []
  private activeType: any = ''
  private pageInfo = {}
  private statusSelect: any = ''
  private alltotal: any = 0

  handleSizeChange(val: number) {
    this.pageSize = val
    this.getTableData()
  }
  handleCurrentChange(val: number) {
    // console.log(this.dataEnd, val)
    // if (val > this.page) {
    //   if (!this.dataEnd) {
    //     this.page = val
    //     this.getTableData()
    //   }
    // } else {
    this.page = val
    this.getTableData()
    // }
  }

  async changeStatus(e: any) {
    this.$nextTick(() => {
      this.statusSelect = ''
    })
    if (!this.tableData.some((item: any) => item.checked)) {
      this.$message({
        type: 'warning',
        message: '请选择需要更改状态的漏洞',
        showClose: true,
      })
      return
    }
    const ids = this.tableData
      .map((item) => {
        if (item.checked) {
          return item.id
        }
      })
      .filter((item) => item)
    const res = await this.services.vuln.changeStatus({
      vul_ids: ids,
      status_id: e,
    })

    if (res.status === 201) {
      this.$message.success(res.msg)
      this.newSelectData()
      return
    }
    this.$message.error(res.msg)
  }

  private async summary() {
    const res = await this.services.vuln.summary()
    if (res.status === 201) {
      this.settingInte = res.data
    }
  }

  private page = 1
  private pageSize = 20
  private dataEnd = false
  private tableData: Array<any> = []
  private searchOptionsObj: any = {
    level: [
      {
        name: '高危',
        num: '0',
        id: 1,
      },
      {
        name: '中危',
        num: '0',
        id: 2,
      },
      {
        name: '低危',
        num: '0',
        id: 3,
      },
      {
        name: '无风险',
        num: '0',
        id: 4,
      },
      {
        name: '提示',
        num: '0',
        id: 5,
      },
    ],
    projects: [],
    vul_source: [],
    hook_type: [],
    orderOptions: [
      {
        label: '漏洞级别',
        value: 1,
      },
      {
        label: '发现时间',
        value: 2,
      },
      {
        label: '最新活跃',
        value: 3,
      },
      {
        label: '状态',
        value: 4,
      },
    ],
    statusOptions: [],
  }

  private async getStatus() {
    const res = await this.services.vuln.vulStatus()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.searchOptionsObj.statusOptions = res.data.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }

  private searchObj: any = {
    level_str: [],
    project_str: '',
    order_type: '',
    keywords: '',
    status_str: [],
    source_type_str: [],
    availability_str: [],
    hook_type_str: [],
    language_str: [],
    sort: null,
  }

  openInNew() {
      const route: any = this.$router.push(
        `/project/scaDetail/${this.rightClickItem.id}/1?status=` +
          this.searchObj.status +
          '&id=' +
          this.rightClickItem.id
      )
      window.open(route.href, '_blank')
  }

  top: any = 0
  left: any = 0
  visible: any = false
  rightClickItem: any = {}

  @Watch('visible', { immediate: true })
  visibleFun(value: any) {
    if (value) {
      document.body.addEventListener('click', this.closeMenu)
    } else {
      document.body.removeEventListener('click', this.closeMenu)
    }
  }

  openMenu(e: any, item: any) {
    this.rightClickItem = item

    var x = e.pageX
    var y = e.pageY

    this.top = y
    this.left = x

    this.visible = true
  }
  closeMenu() {
    this.visible = false
  }

  async created() {
    if (this.$route.query.level_id) {
      this.searchObj.level_str = [this.$route.query.level_id]
    }

    if (this.$route.query.hook_type_str) {
      this.searchObj.hook_type_str = [this.$route.query.hook_type_str]
    }

    this.getStatus()
    this.getTableData()
    this.vulnSummary()
    this.summary()
    this.getProjects()
  }

  private async querySearchAsync(queryString: string, cb: any) {
    const res = await this.services.setting.searchProject({ name: queryString })
    if (res.status === 201) {
      const data = res.data.map((item: any) => {
        return {
          value: item.name,
          id: item.id,
        }
      })
      cb(data)
    }
  }

  private sortSelect(flag: any) {
    this.searchObj.sort = flag
    this.newSelectData()
  }

  private deleteVul(type: string) {
    this.$confirm(
      '该操作将会删除选中漏洞',
      this.$t('views.vulnList.recheckInfo') as string,
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).then(async () => {
      let res: any = {}
      if (!this.tableData.some((item: any) => item.checked)) {
        this.$message({
          type: 'warning',
          message: '请选择需要删除的漏洞',
          showClose: true,
        })
        return
      }
      const ids = this.tableData
        .map((item) => {
          if (item.checked) {
            return item.id
          }
        })
        .filter((item) => item)
      res = await this.services.vuln.vulListDelete({
        source_type: 2,
        ids: String(ids),
      })

      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
        if (type !== 'all') {
          await this.newSelectData()
        }
      }
    })
  }

  private recheck(type: string) {
    this.$confirm(
      `${this.$t('views.vulnList.will')}${
        type === 'all'
          ? this.$t('views.vulnList.all')
          : this.$t('views.vulnList.batch')
      }${this.$t('views.vulnList.recheckDesc')}`,
      this.$t('views.vulnList.recheckInfo') as string,
      {
        confirmButtonText: this.$t(
          'views.vulnList.confirmButtonText'
        ) as string,
        cancelButtonText: this.$t('views.vulnList.cancelButtonText') as string,
        type: 'warning',
      }
    ).then(async () => {
      let res: any = {}
      if (type === 'all') {
        res = await this.services.vuln.vulRecheckAll({ type })
      } else {
        if (!this.tableData.some((item: any) => item.checked)) {
          this.$message({
            type: 'warning',
            message: this.$t('views.vulnList.chooseWarning') as string,
            showClose: true,
          })
          return
        }
        const ids = this.tableData
          .map((item) => {
            if (item.checked) {
              return item.id
            }
          })
          .filter((item) => item)
        res = await this.services.vuln.recheck({
          ids: String(ids),
        })
      }

      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
        if (type !== 'all') {
          await this.newSelectData()
        }
      }
    })
  }

  private selectAll(e: any) {
    const flag =
      this.tableData.length > 0 && this.tableData.every((item) => item.checked)
    if (flag) {
      this.tableData.forEach((item) => this.$set(item, 'checked', false))
    } else {
      this.tableData.forEach((item) => this.$set(item, 'checked', true))
    }
    console.log(e)
  }

  private reset() {
    this.searchObj.level_str = []
    this.searchObj.project_str = ''
    this.searchObj.order_type = ''
    this.searchObj.keywords = ''
    this.searchObj.status_str = []
    this.searchObj.source_type_str = []
    this.searchObj.availability_str = []
    this.searchObj.hook_type_str = []
    this.searchObj.language_str = []
    this.searchObj.sort = null
    this.newSelectData()
  }

  private newSelectData() {
    this.page = 1
    this.dataEnd = false
    this.tableData = []
    if (!this.searchObj.order_type) this.searchObj.sort = null
    this.getTableData()
  }

  timer: any = null
  private myScroll() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      const bottomWindow =
        document.documentElement.scrollTop + window.innerHeight >
        document.documentElement.offsetHeight - 1
      if (bottomWindow) {
        if (!this.dataEnd) {
          this.page += 1
          this.getTableData()
        }
      }
    }, 100)
  }

  private async getTableData(reflash = false) {
    let sort = undefined
    if (this.searchObj.sort === true) {
      sort = 1
    }
    if (this.searchObj.sort === false) {
      sort = 0
    }
    let params = {
        page: this.page,
        page_size: this.pageSize,
        keywords: this.searchObj.keywords || undefined,
        project_id: this.searchObj.project_str ? [Number(this.searchObj.project_str)] : undefined,
        vul_level_id: this.searchObj.level_str.length
          ? this.searchObj.level_str
          : undefined,
        vul_type: this.searchObj.hook_type_str.length
          ? this.searchObj.hook_type_str
          : undefined,
        order_type_desc: sort,
      }

    this.loadingStart()
    const { status, data, msg, page } = await this.services.vuln.vulListContent(
      params
    )
    setTimeout(() => {
      this.loadingDone()
    }, 200)
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    const tableData = (data.messages || data).reduce(
      (list: Array<any>, item: any) => {
        let vul_number = ''
        if (item.vul_cve_nums) {
          vul_number = `${item.vul_cve_nums.cnnvd} | ${item.vul_cve_nums.cnvd} | ${item.vul_cve_nums.cve} | ${item.vul_cve_nums.cwe}`
        }
        list.push({
          ...item,
          first_time: formatTimestamp(item.first_time),
          latest_time: getPassedTime(item.latest_time),
          vul_number: vul_number,
          id: item.id,
          latest_time_nyr: formatTimestamp(item.latest_time),
        })
        return list
      },
      []
    )
    if (tableData.length < this.pageSize) {
      this.dataEnd = true
      this.alltotal = this.page * this.pageSize - 1
    } else {
      this.dataEnd = false
      this.alltotal = this.page * this.pageSize + 1
    }
    // if (reflash) {
    this.tableData = []
    // }
    this.pageInfo = page
    this.tableData = [...this.tableData, ...tableData]
  }
  private SummaryCatch: any = {}
  private boxHeight:any = ''
  private showAccor: boolean = false
  private async vulnSummary() {
    this.searchOptionsObj.level = []
    this.searchOptionsObj.vul_source = []
    this.searchOptionsObj.hook_type = []
    const params = {
      type: 'scan',
    }
    this.searchOptionsObj.orderOptions = [
      {
        label: '漏洞级别',
        value: 1,
      },
      // {
      //   label: '发现时间',
      //   value: 2,
      // },
      {
        label: '最新活跃',
        value: 3,
      },
    ]

    this.loadingStart()
    let res
    if (this.SummaryCatch['scan']) {
      res = this.SummaryCatch['scan']
    } else {
      res = await this.services.vuln.vulSummaryNum(params)
    }
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.SummaryCatch['scan'] = res
    this.searchOptionsObj.hook_type =
      res.data.vul_type && res.data.vul_type.length
        ? res.data.vul_type
        : this.searchOptionsObj.hook_type
    this.searchOptionsObj.level =
      res.data.vul_level && res.data.vul_level.length
        ? res.data.vul_level
        : [
            {
              name: '高危',
              num: '0',
              id: 1,
            },
            {
              name: '中危',
              num: '0',
              id: 2,
            },
            {
              name: '低危',
              num: '0',
              id: 3,
            },
            {
              name: '无风险',
              num: '0',
              id: 4,
            },
            {
              name: '提示',
              num: '0',
              id: 5,
            },
          ]
    this.$nextTick(() => {
      if((this.$refs.accordion as any)?.offsetHeight > 140) {
        this.boxHeight = '135px'
        this.showAccor = true
      } else {
        this.boxHeight = 'auto'
        this.showAccor = false
      }
    // console.log('this.$refs.accordion', (this.$refs.accordion as any)?.offsetHeight)
    })
  }

  private isExpand:boolean = false
  handleAccor() {
    this.isExpand = !this.isExpand
    this.boxHeight = this.isExpand ? 'auto' : '135px'
  }

  private projects: any = []
  private async getProjects(name?: string | undefined) {
    const res = await this.services.project.projectList({
      page: 1,
      pageSize: 50,
      name: name,
    })
    if (res.status === 201) {
      this.projects = res.data
    }
  }
  private async remoteProject(query: string) {
    this.getProjects(query)
  }
}
